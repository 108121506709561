@font-face {
	font-family: Muller;
	src: url(../public/Muller.ttf);
}
@font-face {
	font-family: MullerLight;
	src: url(../public/MullerLight.ttf);
}
@font-face {
	font-family: MullerUltraLight;
	src: url(../public/MullerUltraLight.ttf);
}

body {
	@apply font-semibold;
	font-family: 'Open Sans';
}

.waiting {
	overflow: hidden;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	gap: 160px;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	background: rgb(248, 0, 255);
	background: linear-gradient(
		324deg,
		rgba(248, 0, 255, 1) 0%,
		rgba(0, 125, 255, 1) 100%
	);
	color: white;
}
.ant-picker-ranges .ant-btn-primary {
	@apply bg-blue-400 text-white rounded-lg;
	background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.startmoive {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.scroller {
	scrollbar-width: 30px;
	scrollbar-color: #0a4c95 #c2d2e4;
}
.scroller::-webkit-scrollbar {
	width: 30px;
	height: 15px;
}

.scroller::-webkit-scrollbar-track-piece {
	background-color: #c2d2e4;
}

.scroller::-webkit-scrollbar-thumb:vertical {
	height: 30px;
	background-color: #0a4c95;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	background-color: none !important;
}

.ant-tour-buttons > .ant-tour-next-btn {
	@apply bg-blue-600 text-white;
}

.ant-tour-buttons > .ant-tour-prev-btn {
	@apply border text-black;
}

@media screen and (max-height: 975px) {
	.waiting {
		gap: 20px;
	}
}

.ant-table-tbody tr:nth-child(2n) td {
	background-color: #f4f4f4;
}

.ant-table-thead tr th {
	background-color: #f4f4f4 !important;
}

.ant-tooltip-inner {
	font-size: 18px;
}
